import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'BoredSkullYC - 7,777 Genesis NFT',
    text: '7,777 Bored Skulls bound to a land destroyed by the system. ' + 
    'Journey with them as they regain their power and fight their way home. ' + 
    'Holders of a BoredSkullYC NFT will be allowed to mint up to 5 Club Banners NFT. ',
  },
  {
    title: 'Club Banners NFT - 9,999 Bored Skull Banners  ',
    text: 'Up to 15 Free mints for holders of a Bored Skull.',
  },
  {
    title: 'Bored Skull Tonic - 9,999 Utility Token',
    text: 'This is our Utility Token.',
  },
  {
    title: 'Mutant Skull Club - 9,999 Mutant Skulls',
    text: 'Use Bored Skull Tonic for a Free mint of a Mutant Skull.',
  },
  
  
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Ecosystem</h1>
      <p>BSYC Ecosystem</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
