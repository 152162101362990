import React from 'react';
import gpt3Logo from '../../assets/logo.svg';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Bored Skull Yatch Club NFT</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p><a href="https://boredskullyachtclub.com/mint">Mint Bored Skull</a></p>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2022 BSYC. All rights reserved.</p>
    </div>

  </div>
);

export default Footer;
