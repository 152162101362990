import React from 'react';
import  Feature  from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
   

   <div className="gpt3__whatgpt3-heading">
    <h1 className="gradient__text">Bored Skull Yacht Club</h1>
    
    </div>
   
    
     

    <div className="gpt3__whatgpt3-container">
      <Feature title="Captain Skullbeard" text="Once upon a time, in a land far, far away, there was a group of skeleton pirates who were feeling particularly bored one day. They had sailed the seven seas, raided countless treasure chests, and had all the gold and jewels they could ever want, but they still felt unfulfilled.

One of the pirates, a skeleton named Captain Skullbeard, had an idea. He gathered all of his skeleton pirate friends together and proposed the idea of forming a club for bored skeleton pirates like themselves.

And thus, the Bored Skull Yacht Club was born." />
      
      
      <Feature title="A Brilliant Idea" text="The skeleton pirates spent their days sailing the seas on their trusty ship, the Skele-Toon, and looking for new adventures to keep them entertained. They played games, told tall tales, and sang sea shanties until the sun went down.

But even with all the fun they were having, the skeleton pirates still felt like something was missing.
 That's when Captain Skullbeard had another brilliant idea. He suggested that they use their club to help other bored pirates find excitement and adventure, too.

" />




      <Feature title="The Club" text="And so, the Bored Skull Yacht Club began its mission to help pirates from all over the land find new ways to have fun and avoid boredom. Whether they were sailing the high seas, digging for buried treasure, or just enjoying a good game of skull toss, the skeleton pirates of the Bored Skull Yacht Club were always ready for a new adventure.
 " />
    </div>
  </div>
);

export default WhatGPT3