import React from 'react';
import './header.css';
import ai from '../../assets/aia.gif';
import  Feature  from '../../components/feature/Feature';
import '../../containers/whatGPT3/whatGPT3.css';

const Header = () => {
    return(
    <div>


        <div className="gpt3__header section_padding" id="home"> 
            <div className="gpt3__header-image">
                <img src={ai} alt="aia" />
            </div> 
        </div>


        <div className="gpt3__whatgpt3 section__margin" id="home">
            <div className="gpt3__whatgpt3-container">
                <Feature title="Art Governance Story" text="With the power of decentralization and transparency, a new world order is developing. A world in which we are in control of our our success and freedom. A world in which every member's opinion counts. This technology driven project encompasses art, governance, and story." />
                <Feature title="Transparency  Accountability Utility" text="With transparency, accountability and utility we are taking this brand into the future. We are building on web3 with emphasis on its community as the centerpiece; An audience in the NFT space with keen interest." />
            </div>  
        </div>


    </div>


    )
}

export default Header;