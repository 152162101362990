import React from 'react';
import possibilityImage from '../../assets/possibility.gif';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Utility Advantages </h4>
      <h1 className="gradient__text">Possibilities created <br /> for the Community</h1>

      <p className="gpt3_possibility__text_padding"> NFT Staking Daps.</p>
      <p className="gpt3_possibility__text_padding"> Tokenomics and Merch.</p>
      <p className="gpt3_possibility__text_padding"> Community Voting Daps.</p>
      <p className="gpt3_possibility__text_padding"> Community IRL Events.</p>
      <p className="gpt3_possibility__text_padding"> Community Raffles.</p>
      <p className="gpt3_possibility__text_padding"> Discord Community.</p>
      <p className="gpt3_possibility__text_padding"> Twitter Spaces.</p>
      <p className="gpt3_possibility__text_padding"> Community Events with holder recognition and Prizes.</p>

   
        <p></p>
      <h4>We look forward to bringing holders together in and outside the NFT Space to build a stronger brand.</h4>
    </div>
  </div>
);

export default Possibility;

